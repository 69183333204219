<template>
  <div class="read-ques-look">
    <div class="layout">
      <!-- 用户信息 -->

      <div class="read-title">
        <p>{{ type.title }}</p>
      </div>
      <div class="userInfo">
        <span>测评用户：{{ type.real_name }}</span>
        <el-divider direction="vertical"></el-divider>
        <span>当前总分：{{ type.usercore }}</span>
        <el-divider direction="vertical"></el-divider>
        <span>提交日期：{{ type.submit_time }}</span>
      </div>
      <div class="read-mian" v-for="(v, k, i) in allData" :key="k">
        <!-- 题目类型分类 -->
        <div class="mian_class">
          <span>{{ i | filterNum }} 、{{ k | filterType }}</span>
          (
          <span>共{{ type.type[k].count }}题，</span>
          <span>共{{ type.type[k].core }}分，</span>
          <span>每题{{ type.type[k].average }}分</span>
          )
        </div>
        <!-- 题目信息 -->
        <div v-for="(item, key) in v" :key="key">
          <!-- 题目标题 -->
          <div class="mian_title">
            {{ key + 1 + "、" }}{{ item.title | filteBlank }}
          </div>
          <!-- 题目选择参数 -->
          <div v-if="item.parameter.length > 1">
            <span
              class="mian_parameter"
              v-for="(item2, key) in item.parameter"
              :key="key"
              >{{ item2 }}</span
            >
          </div>
          <el-divider></el-divider>
          <!-- 用户回答参数 -->
          <div class="userAnswer">
            用户答案：
            <span v-if="item.answer == '00'">错误</span>
            <span v-else-if="item.answer == '11'">正确</span>
            <span v-else>{{ item.answer == null ? "无" : item.answer }}</span>
          </div>
          <!-- 标准答案参数 -->
          <div class="referenceAnswer">
            正确答案：
            <span>{{ item.reference_answer }}</span>
          </div>
          <!-- 医生评分+评价 -->
          <div class="evaluate">
            <div class="score">
              得分：
              <el-input v-model="item.usercore" size="mini"></el-input>
            </div>
            <div class="remark">
              <p>点评：</p>
              <el-input
                type="textarea"
                :rows="2"
                v-model="item.comment"
                size="mini"
              ></el-input>
            </div>
          </div>
          <el-divider></el-divider>
        </div>
      </div>
      <el-row type="flex" justify="center">
        <el-button type="primary" @click="readBtn">提交</el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getQueanswer, reviewque } from "@/api/questionnaire.js";
export default {
  data() {
    return {
      allData: [],
      userName: "",
      type: {},
    };
  },
  created() {
    // document.title = this.$route.query.name + "的测评问卷答题结果";
    this.userName = this.$route.query.name;
    this.init();
  },
  methods: {
    // 关闭页面
    isclose() {
      window.close();
    },
    // 页面初始加载
    init() {
      let id = this.$route.query.id;
      getQueanswer({ id }).then((res) => {
        // 数据分类处理
        let obj = {};
        res.data.forEach((v, k) => {
          if (obj.hasOwnProperty(v.type)) {
            obj[v.type].push(v);
          } else {
            obj[v.type] = [];
            obj[v.type].push(v);
          }
        });
        this.allData = obj;
        this.type = res.type;
      });
    },
    // 提交批阅
    readBtn() {
      let data = [];
      for (let k in this.allData) {
        this.allData[k].forEach((v, k2) => {
          let { usercore, id, comment } = v;
          let obj = {
            usercore,
            id,
            comment,
          };
          data.push(obj);
        });
      }
      reviewque({ data, id: this.$route.query.id }).then((res) => {
        if (res.code == 400200) {
          this.$confirm("成功批阅该问卷, 是否关闭此页面?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "success",
          })
            .then(() => {
              this.$router.replace(
                `/read_questionnaire/read?id=${this.$route.query.pid}`
              );
            })
            .catch(() => {});
        }
      });
    },
  },
  filters: {
    filterType(val) {
      let arr = [
        "单选题",
        "多选题",
        "判断题",
        "填空题",
        "排序题",
        "计算题",
        "解答题",
        "问答题",
        "作文题",
      ];
      return arr[val - 1];
    },
    filterNum(val) {
      let arr = [
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十",
        "十一",
      ];
      return arr[val];
    },
    filteBlank(val) {
      return val.replace(/\(\)/g, "(____)");
    },
  },
};
</script>


<style lang="less">
.read-ques-look {
  width: 100%;
  min-width: 320px;
  background: rgb(160, 160, 160);
  .layout {
    padding: 10px 10px;
    max-width: 1200px;
    min-width: 320px;
    margin: 0 auto;
    background: white;
    box-sizing: border-box;
    .read-title {
      text-align: center;
      font-weight: 700;
      font-size: 28px;
      padding-bottom: 10px;
    }
    .userInfo {
      padding: 5px 0;
      display: flex;
      justify-content: center;
    }
    .mian_class {
      background: rgb(217, 242, 247);
      border-radius: 10px;
      margin: 5px 0;
      color: rgb(16, 42, 121);
      font-size: 18px;
      padding: 5px 5px;
    }
    .mian_title {
      font-weight: 600;
      padding: 5px 0;

      word-break: break-all;
    }
    .mian_parameter {
      margin-right: 30px;
      span {
        word-break: break-all;
      }
    }
    .el-divider--horizontal {
      margin: 5px 0;
    }
    .userAnswer {
      padding-bottom: 5px;
      color: rgb(0, 38, 206);
      span {
        word-break: break-all;
      }
    }
    .referenceAnswer {
      padding-bottom: 5px;
      color: green;
      span {
        word-break: break-all;
      }
    }
    .el-input {
      width: 120px;
    }
    .remark {
      padding-top: 5px;
      display: flex;
      p {
        width: 52px;
      }
      .el-textarea {
        flex: 1;
      }
    }
  }
}
</style>